import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Box,
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Stack,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import MeuEditor from "../Components/Editor/CKeditor";

import api from "../services/api";
import { useState, useContext, useEffect, useRef } from "react";
import { MateriaContext } from "../contexts/Materia";
import { AiOutlineScissor } from "react-icons/ai";
import { RiScissorsCutFill } from "react-icons/ri";
import TinyMCEEditor from "../Components/Editor/TinyMCEEditor";
import { BookContext } from "../contexts/Book";

const QuestionMateryList = ({ matery }) => {
  const { createNotebookApi, createAnswerQuestionApi } =
    useContext(BookContext);
  const {
    listQuestoesApi,
    listMateriaApi,
    listMateriaQuestoesApi,
    DetailMateriaApi,
    listSubjectsApi,
    listAllMateriaQuestoesApi,
  } = useContext(MateriaContext);
  const materyID = localStorage.getItem("matery_id");
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isAnswerCorrect, setIsAnswerCorrect] = useState({});
  const [questions, setQuestions] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [user, setUser] = useState();
  const [isAnswerCut, setIsAnswerCut] = useState({});
  const [scissorClickedMap, setScissorClickedMap] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [editorContent, setEditorContent] = useState("");
  const [editorOpenMap, setEditorOpenMap] = useState({});
  const [difficultyID, setDifficultyID] = useState(0);
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState("null");
  const [answeredFilter, setAnsweredFilter] = useState();
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const editorRef = useRef(null);

  const openLoginModal = () => {
    onOpen();
  };

  const handleListQuestion = (e) => {
    setAnsweredFilter(e);
    handleSelectMateria(difficultyID, subject, e);
  };
  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };
    try {
      const response = await api.post("auth/login", data);

      const loggedUser = response.data.user;
      const token = response.data.token;

      localStorage.setItem("user", JSON.stringify(loggedUser));
      localStorage.setItem("token", token);

      setUser(loggedUser);
      setIsLoggedIn(true);
      if (response.status === 200) {
        window.location.reload();
        setIsLoggedIn(true);
        onClose();
      }
      return response;
    } catch (error) {
      JSON.stringify(error);
      //console.log(error.data);
      window.location.reload();
      return error;
    }
  };
  const handleUpdateContent = (newContent) => {
    setEditorContent(newContent);
  };
  const handleCutAnswer = (questionId) => {
    // Verifica se a resposta já foi cortada
    const isAlreadyCut = isAnswerCut[questionId] || false;

    // Atualiza o estado isAnswerCut para indicar se a resposta foi cortada ou não
    setIsAnswerCut((prevIsAnswerCut) => ({
      ...prevIsAnswerCut,
      [questionId]: !isAlreadyCut,
    }));

    // Lógica adicional para alterar o estado do botão, texto ou outros comportamentos
    const newSelectedAnswers = { ...selectedAnswers };
    if (isAlreadyCut) {
      delete newSelectedAnswers[questionId];
    }
    setSelectedAnswers(newSelectedAnswers);
  };
  async function handleSelectMateria(difficulty, subjects, answerList) {
    const materiaID = localStorage.getItem("matery_id");
    const subjectList = subjects; // Agora é um array de assuntos
    const answered = answerList;

    //console.log(subjects);
    try {
      const materiaInfoApi = await listAllMateriaQuestoesApi(
        materiaID,
        difficulty,
        subjectList, // Passando o array de assuntos
        answered
      );
      setQuestions(materiaInfoApi.data?.questions);
    } catch (error) {
      //console.log(error);
    }
  }

  const handleChangeSubject = (selectedValues) => {
    setSelectedSubjects(selectedValues);

    const selectedIds = selectedValues.map(
      (selectedOption) => selectedOption.value
    );

    //console.log(selectedIds);
    if (selectedIds.length > 0) {
      handleSelectMateria(difficultyID, selectedIds, answeredFilter); // Envia apenas os IDs
    } else {
      handleSelectMateria(difficultyID, [], answeredFilter); // Envia um array vazio se não houver seleção
    }
  };

  async function getAssuntosMateria() {
    const materiaID = localStorage.getItem("matery_id");
    try {
      const subjectsApi = await listSubjectsApi(materiaID);
      //console.log(subjectsApi.data);
      setSubjects(subjectsApi.data?.[0]);
    } catch (error) {
      //console.log(error);
    }
  }
  function userLogged() {
    const user = JSON.parse(localStorage.getItem("user")); // Converte o JSON para objeto
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    //console.log(user);
    if (!user) {
      setIsLoggedIn(false);
      window.location.reload(); // Se o usuário não estiver logado, exiba o modal de login
    } else {
      api
        .get(`/user/${user.id}/status`, { headers })
        .then((response) => {
          setIsLoggedIn(true);
        })
        .catch((error) => {
          setIsLoggedIn(false);
          if (error.response && error.response.status === 401) {
            setIsLoggedIn(false); // Exiba o modal de login se ocorrer um erro de autenticação
          } else {
            setIsLoggedIn(true);
            window.location.reload();
          }
        });
    }
  }

  useEffect(() => {
    userLogged();

    handleSelectMateria(null, null);
    getAssuntosMateria();
  }, [materyID]);

  const handleChangeDifficulty = (value) => {
    setSubject(null);
    handleSelectMateria(value);
    setDifficultyID(value);

    if (value > 0) {
      handleSelectMateria(value, null);
    } else {
      handleSelectMateria(null, null);
    }
  };

  const handleAnswerSelection = (questionId, answerId) => {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionId]: answerId,
    }));
  };

  const handleAnswerSubmission = async (questionId) => {
    // Obtém a resposta selecionada pelo usuário
    const selectedAnswer = selectedAnswers[questionId];
    if (!isLoggedIn) {
      // Se o usuário não estiver logado, abrir o modal de login
      openLoginModal();
      return;
    }
    // Verifica se a resposta foi selecionada
    if (!selectedAnswer) {
      return;
    }

    // Obtém a pergunta correspondente ao ID
    const question = questions.find((q) => q.id === questionId); // Corrija esta linha

    if (!question) {
      return;
    }

    const data = {
      question_id: questionId,
      answer_id: selectedAnswer,
    };
    try {
      const response = await createAnswerQuestionApi(data);
      //console.log(response.data);
      if (response.status === 200) {
        const correctAnswerIndex = question.answers.findIndex(
          (answer) => answer.answer_verify === 1
        );

        const isCorrect =
          question.answers[correctAnswerIndex].id === selectedAnswer;

        setIsAnswerCorrect((prevIsAnswerCorrect) => ({
          ...prevIsAnswerCorrect,
          [questionId]: isCorrect,
        }));

        const selectedIds = selectedSubjects.map(
          (selectedOption) => selectedOption.value
        );

        if (selectedIds.length > 0) {
          handleSelectMateria(difficultyID, selectedIds, answeredFilter); // Envia apenas os IDs
        } else {
          handleSelectMateria(difficultyID, [], answeredFilter); // Envia um array vazio se não houver seleção
        }

        toast({
          title: "Resposta enviada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      //console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
  };

  const handleNotebookSave = async (value) => {
    //console.log(value);
    if (!isLoggedIn) {
      // Se o usuário não estiver logado, abrir o modal de login
      openLoginModal();
      return;
    } else {
      try {
        const userInfo = JSON.parse(localStorage.getItem("user"));
        const data = {
          question_id: value.id,
          user_id: userInfo.id,
          notebook: editorContent,
        };
        const response = await createNotebookApi(data);
        if (response.status == 200) {
          toast({
            title: "Caderno Atualizado com Sucesso",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        if (error.response.status == 401) {
          toast({
            title: "Sua sessão expirou, faça o login novamente",
            status: "error",
            isClosable: true,
          });
          openLoginModal();
          return;
        }
      }
    }
  };
  const options = subjects.map((item) => ({
    value: item.id,
    label: item.subject,
  }));

  const optionsDific = [
    { value: "1", label: "Iniciante" },
    { value: "2", label: "Intermediário" },
    { value: "3", label: "Avançado" },
  ];

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsAnswerCorrect({});
      setIsAnswerCut({});
      setSelectedAnswers({});
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <>
      <Flex justifyContent={"center"}>
        <Box borderRadius="md" p={4} width={"700px"} color={"white"}>
          <Box>
            <Text mb={2}>Filtrar por dificuldade:</Text>
            <Select
              options={optionsDific}
              placeholder="Selecione a dificuldade"
              onChange={(selectedOption) =>
                handleChangeDifficulty(selectedOption.value)
              }
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  background: "white",
                  borderColor: "#CBD5E0",
                  borderRadius: "0.375rem",
                  boxShadow: "none",
                  maxWidth: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  "&:hover": {
                    borderColor: "#A0AEC0",
                  },
                }),
                multiValue: (provided) => ({
                  ...provided,
                  maxWidth: "50%", // Limita a largura do badge
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }),
                menu: (provided) => ({
                  ...provided,
                  background: "white",
                  borderRadius: "0.375rem",
                }),
                option: (provided, state) => ({
                  ...provided,
                  background: state.isFocused ? "#EDF2F7" : "white",
                  color: state.isFocused ? "#2D3748" : "#4A5568",
                  "&:hover": {
                    background: "#E2E8F0",
                  },
                }),
              }}
            />
          </Box>

          <Text mt={5}>Filtrar por assunto</Text>
          <Box width="100%">
            <Select
              options={options}
              placeholder="Selecionar assunto"
              value={selectedSubjects} // O valor aqui é um array de objetos
              onChange={handleChangeSubject} // Passa o array completo de objetos
              isSearchable
              isMulti
              getOptionLabel={(e) => e.label} // Como o label é 'label' e não 'subject'
              getOptionValue={(e) => e.value} // A chave 'value' vai ser usada como valor para cada opção
              styles={{
                control: (provided) => ({
                  ...provided,
                  background: "white",
                  borderColor: "#CBD5E0", // Chakra's gray.300
                  borderRadius: "0.375rem", // Chakra's md
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#A0AEC0", // Chakra's gray.400
                  },
                }),
                menu: (provided) => ({
                  ...provided,
                  background: "white",
                  borderRadius: "0.375rem",
                }),
                option: (provided, state) => ({
                  ...provided,
                  background: state.isFocused ? "#EDF2F7" : "white", // Chakra's gray.100
                  color: state.isFocused ? "#2D3748" : "#4A5568", // Chakra's gray.800 and gray.600
                  "&:hover": {
                    background: "#E2E8F0", // Chakra's gray.200
                  },
                }),
              }}
            />
          </Box>

          {difficultyID != 0 ? (
            <RadioGroup
              defaultValue="all"
              onChange={(e) => handleListQuestion(e)}
            >
              <Stack direction="row" color={"#050F40"}>
                <Radio value="all">Todas</Radio>
                <Radio value="true">Respondidas</Radio>
                <Radio value="false">Não Respondidas</Radio>
              </Stack>
            </RadioGroup>
          ) : (
            " "
          )}
        </Box>
      </Flex>
      <Box>
        {questions?.map((question, index) => {
          const correctAnswerIndex = question.answers.findIndex(
            (answer) => answer.answer_verify === 1
          );

          const questionId = question?.id;

          return (
            <>
              <Flex justifyContent="center">
                <Box key={index} my={2} width={"90vw"}>
                  <Box
                    borderWidth="1px"
                    borderRadius="md"
                    p={4}
                    width={"100%"}
                    bg={"white"}
                    color={"black"}
                    padding={"45px"}
                  >
                    <Text fontWeight="bold">Questão {index + 1}:</Text>
                    <Box mt={1} mb={5}>
                      <Box fontWeight={"bold"} fontSize={"14px"} pr={2}>
                        <Text> Materia: {matery}</Text>
                      </Box>
                      <Box fontWeight={"bold"} fontSize={"14px"}>
                        <Text>Dificuldade: {question.difficulty_name}</Text>
                      </Box>
                      <Box>
                        {question.subject != null ? (
                          <Text fontSize={"14px"} fontWeight={"bold"}>
                            Assunto: {question.subject?.subject}
                          </Text>
                        ) : (
                          ""
                        )}
                      </Box>

                      <Box display="inline-flex" flexWrap="wrap" gap="10px">
                        <Text fontSize={"14px"} fontWeight={"bold"}>
                          Assuntos Secundário:
                        </Text>
                        {question?.secondary_subjects?.map((subject) => (
                          <Text
                            fontSize="14px"
                            color={"black"}
                            key={subject.id}
                          >
                            {subject?.subject}
                          </Text>
                        ))}
                      </Box>
                    </Box>

                    <Box
                      fontSize={"18px"}
                      dangerouslySetInnerHTML={{
                        __html: question.question,
                      }}
                    />

                    {question?.answers.map((answer, index) => {
                      const isAnswerSelected =
                        selectedAnswers[question.id] === answer.id;
                      const isCorrectAnswer = index === correctAnswerIndex;
                      const isMyAnswer =
                        question.my_answer &&
                        answer.id === question.my_answer.answer_id;

                      return (
                        <Box
                          bg={"white"}
                          key={answer.id}
                          display="flex"
                          alignItems="center"
                          mt={2}
                          color={"black"}
                          cursor="pointer"
                        >
                          <Button
                            ml={2}
                            p={"0px"}
                            borderRadius={100}
                            fontSize={
                              isCorrectAnswer && question.my_answer
                                ? "20px"
                                : "14px"
                            }
                            fontWeight={
                              isCorrectAnswer && question.my_answer
                                ? "bold"
                                : "normal"
                            }
                            color={
                              isCorrectAnswer && question.my_answer
                                ? "#050F40"
                                : "black"
                            }
                            variant={isAnswerSelected ? "solid" : "outline"}
                            colorScheme={isAnswerSelected ? "gray" : "gray"}
                            isDisabled={
                              isAnswerCut[answer.id] ||
                              (question.my_answer && isMyAnswer)
                            }
                            onClick={() =>
                              handleAnswerSelection(question.id, answer.id)
                            }
                            translate="no"
                            aria-label={`Option ${String.fromCharCode(
                              65 + index
                            )}`} // Rótulo acessível
                          >
                            {String.fromCharCode(65 + index)}{" "}
                            {/* Letras A, B, C, D, E */}
                          </Button>
                          <Box
                            ml={2}
                            flex="1"
                            css={{
                              textDecoration: scissorClickedMap[answer.id]
                                ? "line-through"
                                : "none",
                              color: scissorClickedMap[answer.id]
                                ? "#ccc"
                                : isCorrectAnswer && question.my_answer
                                ? "#050F40"
                                : "black",
                            }}
                            fontSize={
                              isCorrectAnswer && question.my_answer
                                ? "18px"
                                : "14px"
                            }
                            fontWeight={
                              isCorrectAnswer && question.my_answer
                                ? "bold"
                                : "normal"
                            }
                            dangerouslySetInnerHTML={{ __html: answer.answer }}
                          />
                        </Box>
                      );
                    })}

                    <Box
                      display="flex"
                      flexDirection={{ base: "column", md: "row" }} // No mobile (base) = coluna (empilhado), a partir do md (768px) = linha (lado a lado)
                      justifyContent="center"
                      mt={4}
                      mb={5}
                    >
                      <Button
                        mb={{ base: 4, md: 0 }} // Margem inferior apenas no mobile
                        mr={{ base: 0, md: 5 }} // Margem à direita no desktop
                        bgColor={"#FAB929"}
                        onClick={() => {
                          setEditorOpenMap((prevEditorOpenMap) => ({
                            ...prevEditorOpenMap,
                            [question.id]: !prevEditorOpenMap[question.id], // Invert the open state
                          }));
                        }}
                      >
                        {!editorOpenMap[question.id]
                          ? "Adicionar Anotação"
                          : "Fechar Editor"}
                      </Button>
                      <Button
                        bgColor={"#FAB929"}
                        onClick={() => {
                          handleAnswerSubmission(question.id);
                          userLogged();
                        }}
                        isDisabled={!selectedAnswers[question.id]}
                      >
                        Enviar Resposta
                      </Button>
                    </Box>
                    {question.my_answer != null &&
                      question?.my_answer?.answer === 1 && (
                        <Box display="flex" justifyContent="center" mt={2}>
                          <Text color="green">Resposta Correta!</Text>
                        </Box>
                      )}
                    {question.my_answer != null &&
                      question?.my_answer?.answer === 0 && (
                        <Box display="flex" justifyContent="center" mt={2}>
                          <Text color="red">
                            Resposta incorreta. A resposta correta é:{" "}
                            {String.fromCharCode(65 + correctAnswerIndex)}
                          </Text>
                        </Box>
                      )}
                    <Flex justifyContent="center" flexDirection="column">
                      {/* Resto do seu código da pergunta */}
                      {editorOpenMap[question.id] && (
                        <>
                          <MeuEditor
                            ref={editorRef}
                            initialValue={question.notebook?.book}
                            onChange={(newContent) =>
                              handleUpdateContent(newContent)
                            }
                          />
                          <Button
                            mt={2}
                            onClick={() => handleNotebookSave(question)}
                          >
                            {" "}
                            Salvar{" "}
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Box>
                </Box>
              </Flex>
            </>
          );
        })}

        {/* Modal de login */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Login</ModalHeader>
            <ModalBody>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Button mt={5} onClick={handleLogin}>
                {" "}
                Login{" "}
              </Button>
            </ModalBody>
            <Box maxW={"350px"}>
              <Stack
                ml={5}
                pb={2}
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Text>Ainda não tem Cadastro?</Text>

                <Link to={"/cadastro"}>
                  <Text color={"#FAB929"} fontWeight={"bold"}>
                    Cadastre-se
                  </Text>
                </Link>
              </Stack>
            </Box>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default QuestionMateryList;
